:root {
    --white: #ffffff;
    --black: #000000;
    --gray: rgba(0, 0, 0, 0.5);
    --light-gray: #ededee;
    --darked-gray: #484848;
    --ultra-light-gray: #EFEFEF;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "RobotoSlab-Regular", sans-serif;
    -webkit-tap-highlight-color: transparent;
}

.no-scroll {
    overflow: hidden;
}

.container {
    width: 1700px;
    margin: 0 auto;
}

.large-headline {
    font-family: "BebasNeue-Regular-Cyrilic", sans-serif;
    font-size: 100px;
}

.main-text {
    font-size: 20px;
    line-height: 120%;
}

.subtitle-bold {
    font-family: "RobotoSlab-Bold", sans-serif;
    font-size: 30px;
}

.subtitle-regular {
    font-size: 30px;
}

.header {
    padding: 10px 0;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    border-bottom: 2px solid var(--black);
}

.header-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-top a {
    display: flex;
    align-items: center;
}

.header-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 130px;
    margin-bottom: 30px;
}

.header-logo {
    width: 340px;
    min-width: 340px;
}

.header-logo-sk {
    width: 150px;
    min-width: 100px;
}

.menu-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.burger-menu {
    display: none;
}

.menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
}

.menu li {
    position: relative;
}

.menu a:hover {
    color: var(--darked-gray);
}

.menu li.current-menu-item:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--black);
}

.menu a {
    font-family: "BebasNeue-Regular-Cyrilic", sans-serif;
    font-size: 50px;
}

.call-logo {
    width: 56px;
    height: 56px;
}

.control-slider {
    display: flex;
    position: absolute;
    align-items: center;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.body-counter-slide {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin: 0 15px;
}

.count-slide {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    border: 2px solid var(--white);
    border-radius: 100%;
}

.count-slide.active {
    background: var(--white);
}

.btn-control-slider {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    cursor: pointer;
}

.block {
    margin-top: 100px;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--black);
    margin-bottom: 100px;
}

.wrapper-technopark {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 630px;
    margin-bottom: 60px;
}

.wrapper-list-residents {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    margin-bottom: 100px;
}

.list-residents {
    display: flex;
    flex-wrap: wrap;
}

.list-residents.technopark {
    flex-direction: row-reverse;
}

.title-residents {
    font-size: 24px;
}

.resident {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 20px;
    padding: 30px 60px 30px 60px;
    border: 2px solid var(--black);
    margin-left: -2px;
    margin-top: -2px;
    width: calc(100% / 4);
    min-width: calc(100% / 4);
    max-width: calc(100% / 4);
}

.resident:hover {
    background: var(--light-gray);
}

.resident:hover .resident-svg > path {
    fill: var(--darked-gray);
}

.resident-svg {
    width: 240px;
    height: 240px;
}

.row-residents {
    display: flex;
    width: 100%;
}

.wrapper-title-residents {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.wrapper-events {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.wrapper-title-events {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--black);
}

.archive-events {
    display: none;
}

.slider-events {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider-events-wrapper-body {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.slider-events-body {
    scroll-behavior: smooth;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    display: flex;
    column-gap: 20px;
    width: 100%;
    transition: all 1s ease-in-out;
}

.slider-events-body::-webkit-scrollbar {
    display: none;
}

.event {
    user-select: none;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.slider-events-body .event:first-child {
    scroll-snap-align: start;
}

.slider-events-body .event:nth-child(4n + 1) {
    scroll-snap-align: start;
}

.slider-events-body .event:last-child {
    scroll-snap-align: end;
}

.event-photo {
}

.text-event {
    /*-webkit-line-clamp: 3;*/
    /*line-clamp: 3;*/
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 20px;
    line-height: 120%;
}

.title-event {
    padding-top: 5px;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: "BebasNeue-Regular-Cyrilic", sans-serif;
    font-size: 60px;
    line-height: 64px;
}

.date-publish {
    font-size: 20px;
    font-family: "RobotoSlab-Bold", sans-serif;
}

.main-content-event {
    height: 160px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 20px;
}

.count-slide-event {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    border: 2px solid var(--black);
    border-radius: 100%;
}

.count-slide-event-active {
    background: var(--black);
}

.control-slider-events {
    display: flex;
    margin-top: 35px;
}


.block.time-work {
    margin-bottom: 20px;
}

.time-work-content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 150px;
}

.block.contacts {
    margin-bottom: 20px;
}

.contacts-text {
    font-size: 20px;
    line-height: 120%;
}

.contacts-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.wrapper-contacts {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

.social-item {
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}

.social {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.title-contact {
    margin-bottom: 20px;
}

footer {
}

#yandex {
    height: 650px;
    width: 65%;
}

.marker-class {
    cursor: pointer;

    width: 75px;
    height: 75px;
    border-radius: 50%;

    position: relative;
    transform: translate(-50%, -50%);
}

.ymaps3x0--default-marker__title-wrapper {
    max-width: 100% !important;
}

.block-footer {
    display: flex;
    align-items: center;
    margin-top: 100px;
    height: 80px;
    width: 100%;
    background: var(--black);
}

.inner-block-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.copyright,
.policy {
    color: var(--ultra-light-gray);
    font-size: 20px;
}

.link {
    text-decoration: underline;
    color: var(--ultra-light-gray);
    font-size: 20px;
}

.main-text.link.mobile {
    display: none;
}

.list-events {
    display: none;
}

.wrapper-head-category {
    width: 100%;
    height: 500px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 80px;
}

.photo-bg-cover {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.head-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
}

.head-category-svg {
    width: 300px;
    height: 300px;
}

.title-head-category {
    font-size: 24px;
}

.list-category-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 40px;
    column-gap: 20px;
    margin-bottom: 100px;
}

.category-item {
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    min-width: calc(50% - 10px);
    padding: 10px;
}

.category-item-tag {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 34px;
    height: 34px;
}

.category-item-tag img {
    width: 34px;
    height: 34px;
}

.category-item:hover {
    background: var(--ultra-light-gray);
}

.wrapper-img-category-item {
    position: relative;
    overflow: hidden;
    width: 415px;
    min-width: 415px;
    height: 415px;
    min-height: 415px;
    background-color: transparent;
}

.wrapper-img-category-item.full {
    background-color: white;
}

.img-category-item {
    position: absolute;
    width: 90%;
    height: 90%;
}

.wrapper-img-category-item.full .img-category-item {
    position: initial;
    width: 100%;
    height: 100%;
}

.wrapper-img-category-item .img-category-item:first-child {
    right: 0;
    top: 0;
}

.wrapper-img-category-item .img-category-item:nth-child(2) {
    right: 50%;
    transform: translate(50%, -50%);
    top: 50%;
}

.wrapper-img-category-item .img-category-item:last-child {
    top: initial;
    transform: initial;
    right: initial;
    left: 0;
    bottom: 0;
}


.type-resident {
    font-family: "RobotoSlab-Light", sans-serif;
    font-size: 24px;
}

.name-resident {
    font-size: 60px;
    line-height: 64px;
    font-family: "BebasNeue-Regular-Cyrilic", sans-serif;
}

.top-block-content-category-item {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.wrapper-text-category-item {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}


.time-work-resident {
    font-size: 20px;
    font-family: "RobotoSlab-Bold", sans-serif;
}

.description-resident {
    padding-bottom: 2px;
    /*line-clamp: 6;*/
    /*-webkit-line-clamp: 6;*/
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-size: 20px;
    line-height: 24px;
}

.link-to-resident {
    font-family: "RobotoSlab-Bold", sans-serif;
    font-size: 20px;
    word-break: break-all;
}

.wrapper-content-category-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.primary-button {
    font-size: 24px;
    background: var(--white);
    outline: none;
    border: 2px solid var(--black);
    padding: 30px 115px;
    cursor: pointer;
}

.primary-button:hover {
    background: var(--ultra-light-gray);
}

.wrapper-become-resident {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 100px;
}

.wrapper-other-residents {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
}


.list-residents-category {
    justify-content: center;
    width: 70%;
}

.resident-category {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
}

.block-service {
    margin-bottom: 20px !important;
}

.img-bg-contain {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.map-lpm {
    width: 100%;
}


.swiper-events {
    width: 100%;
    overflow: hidden;
}

.swiper-events > .swiper-wrapper {
    display: flex;
    flex-direction: row;
}

.swiper-slide {
    min-width: calc((100% / 4) - 15px);
    max-width: calc((100% / 4) - 15px);
}

.swiper-controls-events {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.swiper-pagination {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}


.wrapper-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 60px;
}

.status-error {
    font-family: "BebasNeue-Regular-Cyrilic", sans-serif;
    font-size: 400px;
}

.swiper-main {
    width: 100%;
    overflow: hidden;
    position: relative;
}


.swiper-main-wrapper {
    display: flex;
    height: 700px;
}

.swiper-main-controls {
    display: flex;
    align-items: center;
    column-gap: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
}

.count-slide-main {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    border: 2px solid var(--white);
    border-radius: 100%;
}

.count-slide-main-active {
    background: var(--white);
}


.swiper-main-slide {
    width: 100%;
    min-width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.img-event {
    width: 100%;
    height: 100%;
}

#yandex canvas {
    filter: grayscale(1) !important;
}

.swiper-main-slide.one {
    background-image: url(/wp-content/themes/lpm/assets/img/slider1.webp);
}

.swiper-main-slide.two {
    background-image: url(/wp-content/themes/lpm/assets/img/slider2.webp);
}

.swiper-main-slide.three {
    background-image: url(/wp-content/themes/lpm/assets/img/slider3.webp);
}

.swiper-main-slide.four {
    background-image: url(/wp-content/themes/lpm/assets/img/slider4.webp);
}

.swiper-main-slide.five {
    background-image: url(/wp-content/themes/lpm/assets/img/kidz-cover.webp);
}

.swiper-main-slide.six {
    background-image: url(/wp-content/themes/lpm/assets/img/slider6.webp);
}


@media (max-width: 1699.98px) {
    .container {
        width: 1400px;
    }

    .resident-svg {
        width: 200px;
        height: 200px;
    }

    .title-event {
        font-size: 50px;
        line-height: 54px;
    }

    .date-publish {
        font-size: 20px;
    }

    /*.event-photo {*/
    /*    height: 335px;*/
    /*}*/
    .primary-button {
        padding: 20px 70px;
    }

    .wrapper-img-category-item {
        width: 300px;
        min-width: 300px;
        height: 300px;
        min-height: 300px;
    }

    .name-resident {
        font-size: 50px;
        line-height: 54px;
    }

    .type-resident {
        font-size: 20px;
    }

    .time-work-resident,
    .description-resident,
    .link-to-resident {
        font-size: 18px;
        line-height: 22px;
    }

    .head-category-svg {
        width: 270px;
        height: 270px;
    }

    .swiper-main-wrapper {
        height: 600px;
    }
}

@media (max-width: 1399.98px) {
    .container {
        width: 1200px;
    }

    .large-headline {
        font-size: 70px;
    }

    .resident-svg {
        width: 150px;
        height: 150px;
    }

    .title-residents {
        font-size: 18px;
    }

    .block {
        margin-bottom: 80px;
        margin-top: 80px;
    }

    .wrapper-list-residents {
        margin-bottom: 80px;
    }

    .time-work-content {
        margin-bottom: 100px;
    }

    .event {
        width: calc((100% / 3) - 13.3px);
        min-width: calc((100% / 3) - 13.3px);
        max-width: calc((100% / 3) - 13.3px);
    }

    .slider-events-body .event:nth-child(4n + 1) {
        scroll-snap-align: none;
    }

    .slider-events-body .event:nth-child(3n + 1) {
        scroll-snap-align: start;
    }

    .top-block-content-category-item {
        row-gap: 5px;
    }

    /*.description-resident {*/
    /*    -webkit-line-clamp: 4;*/
    /*    line-clamp: 4;*/
    /*}*/
    .wrapper-img-category-item {
        height: 280px;
        min-height: 280px;
    }

    .head-category-svg {
        height: 200px;
        width: 200px;
    }

    .wrapper-head-category {
        height: 400px;
    }

    .title-head-category {
        font-size: 22px;
    }

    .status-error {
        font-size: 350px;
    }

    .swiper-main-wrapper {
        height: 550px;
    }
}

@media (max-width: 1199.98px) {

    .container {
        width: 992px;
    }

    .large-headline {
        font-size: 50px;
    }

    .list-residents {
        justify-content: center;
    }

    .resident {
        width: calc(100% / 3);
        min-width: calc(100% / 3);
        max-width: calc(100% / 3);
    }

    .menu a {
        font-size: 40px;
    }

    .resident-svg {
        width: 200px;
        height: 200px;
    }

    .wrapper-technopark {
        row-gap: 15px;
        margin: 0 auto 40px;
        text-align: center;
    }

    .wrapper-list-residents {
        text-align: center;
    }

    .contacts-content {
        flex-direction: column;
        row-gap: 50px;
    }

    #yandex {
        width: 100%;
    }

    .title-contact {
        margin-bottom: 10px;
    }

    .main-text,
    .contacts-text {
        font-size: 18px;
    }

    .subtitle-bold {
        font-size: 24px;
    }

    .time-work-content {
        margin-bottom: 80px;
    }

    .wrapper-contacts {
        row-gap: 20px;
    }


    .wrapper-head-category {
        height: 400px;
        margin-top: 40px;
        margin-bottom: 65px;
    }

    .name-resident {
        font-size: 50px;
        line-height: 54px;
    }

    .time-work-resident, .description-resident, .link-to-resident, .type-resident {
        font-size: 20px;
        line-height: 24px;
    }

    .wrapper-img-category-item {
        height: 240px;
        min-height: 240px;
    }

    .title-head-category {
        font-size: 20px;
    }

    .head-category-svg {
        height: 160px;
        width: 160px;
    }

    .category-item {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    .wrapper-img-category-item {
        width: 400px;
        min-width: 400px;
        height: 400px;
        min-height: 400px;
    }

    .wrapper-head-category {
        height: 350px;
    }

    .list-residents-category {
        width: 100%;
    }

    .list-residents.technopark {
        flex-direction: row;
    }

    .swiper-main-wrapper {
        height: 450px;
    }

    .swiper-main-slide.one {
        background-image: url(/wp-content/themes/lpm/assets/img/slider1-1200.webp);
    }

    .swiper-main-slide.two {
        background-image: url(/wp-content/themes/lpm/assets/img/slider2-1200.webp);
    }

    .swiper-main-slide.three {
        background-image: url(/wp-content/themes/lpm/assets/img/slider3-1200.webp);
    }

    .swiper-main-slide.four {
        background-image: url(/wp-content/themes/lpm/assets/img/slider4-1200.webp);
    }

    .swiper-main-slide.five {
        background-image: url(/wp-content/themes/lpm/assets/img/kidz-cover-1200.webp);
    }

    .swiper-main-slide.six {
        background-image: url(/wp-content/themes/lpm/assets/img/slider6.webp);
    }
}

@media (max-width: 991.98px) {

    .container {
        width: 800px;
    }

    .resident-svg {
        width: 130px;
        height: 130px;
    }

    .archive-events {
        display: block;
    }

    .resident {
        padding: 30px
    }

    .slider-events {
        display: none;
    }

    .list-events {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
    }

    .date-publish,
    .text-event {
        font-size: 18px;
    }

    .mobile-event-img {
        width: 350px;
        height: 350px;
    }

    .wrapper-content-mobile-event {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    /*.text-event {*/
    /*    -webkit-line-clamp: 5;*/
    /*    line-clamp: 5;*/
    /*}*/
    .mobile-event {
        display: flex;
        column-gap: 10px;
        height: 350px;
        align-items: flex-start;
    }

    .wrapper-title-mobile-event {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }

    .swiper-events {
        display: none;
    }

    .primary-button {
        font-size: 20px;
        padding: 20px 70px;
    }

    .head-category-svg {
        height: 140px;
        width: 140px;
    }

    .list-category-items {
        margin-bottom: 60px;
    }

    .wrapper-become-resident {
        margin-bottom: 60px;
    }

    .status-error {
        font-size: 300px;
    }

    .wrapper-error {
        row-gap: 40px;
    }

    .swiper-main-wrapper {
        height: 350px;
    }

    .swiper-main-slide.one {
        background-image: url(/wp-content/themes/lpm/assets/img/slider1-990.webp);
    }

    .swiper-main-slide.two {
        background-image: url(/wp-content/themes/lpm/assets/img/slider2-990.webp);
    }

    .swiper-main-slide.three {
        background-image: url(/wp-content/themes/lpm/assets/img/slider3-990.webp);
    }

    .swiper-main-slide.four {
        background-image: url(/wp-content/themes/lpm/assets/img/slider4-990.webp);
    }

    .swiper-main-slide.five {
        background-image: url(/wp-content/themes/lpm/assets/img/kidz-cover-990.webp);
    }

    .swiper-main-slide.six {
        background-image: url(/wp-content/themes/lpm/assets/img/slider6-990.webp);
    }
}

@media (max-width: 767.98px) {

    .container {
        width: 100%;
        padding: 0 10px;
    }

    .header-logo {
        width: 280px;
        min-width: 280px;
        max-height: 50px;
    }

    .archive-events svg {
        width: 30px;
        height: 30px;
    }


    .header-logo-sk {
        width: 100px;
        min-width: 100px;
        max-height: 50px;
    }

    .header {
        padding: 10px 0 20px;
    }

    .burger-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 40px;
        height: 40px;
    }

    .icon-burger-menu {
        position: absolute;
        width: 100%;
        height: 2px;
        background: var(--black);
        transition: all .2s ease-in-out;
    }

    .burger-menu {
        width: 40px;
        height: 20px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    .burger-menu span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: var(--black);
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    .burger-menu span:nth-child(1) {
        top: 0px;
        transform-origin: left center;
    }

    .burger-menu span:nth-child(2) {
        top: 50%;
        transform-origin: left center;
    }

    .burger-menu span:nth-child(3) {
        top: 100%;
        transform-origin: left center;
    }

    .burger-menu.active span:nth-child(1) {
        transform: rotate(-45deg) translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    .burger-menu.active span:nth-child(2) {
        width: 0%;
        opacity: 0;
    }

    .burger-menu.active span:nth-child(3) {
        transform: rotate(45deg) translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    .burger-menu-svg {
        width: 40px;
        height: 20px;
    }

    .large-headline {
        font-size: 36px;
    }

    .main-text {
        font-size: 14px;
    }

    .wrapper-technopark {
        width: 300px;
        row-gap: 10px;
    }

    .block {
        margin-bottom: 60px;
        margin-top: 60px;
    }

    .resident-svg {
        width: 90px;
        height: 90px;
    }

    .title-residents {
        font-size: 12px;
    }

    .subtitle-bold {
        font-size: 18px;
    }

    #yandex {
        height: 400px;
    }

    .contacts-text {
        font-size: 14px;
    }

    .header-bottom {
        justify-content: center;
        margin-bottom: 0;
    }

    .menu-container {
        display: none;
        align-items: flex-start;
        row-gap: 35px;
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100dvh;
        top: 100px;
        background: rgba(0, 0, 0, 0.54);
    }

    .menu-container.active {
        display: flex;
    }

    .mobile-container-header {
        width: 100%;
        position: relative;
    }

    .menu {
        background: var(--white);
        flex-direction: column;
        row-gap: 30px;
        padding: 30px 0 50px;
    }

    .mobile-event {
        height: 300px;
    }

    .text-event,
    .date-publish {
        font-size: 14px;
    }

    .btn-control-slider {
        width: 15px;
        height: 15px;
        min-height: 15px;
        min-width: 15px;
    }

    .count-slide {
        width: 5px;
        height: 5px;
        min-width: 5px;
        min-height: 5px;
        border: 1px solid var(--white);
    }

    .control-slider {
        bottom: 15px;
    }

    .title-event {
        height: fit-content;
        min-height: fit-content;
        font-size: 40px;
        line-height: 44px;
    }

    .primary-button {
        font-size: 18px;
    }

    .wrapper-img-category-item {
        width: 250px;
        height: 250px;
        min-height: 250px;
        min-width: 250px;
    }

    .name-resident {
        font-size: 36px;
        line-height: 40px;
    }

    .time-work-resident, .description-resident, .link-to-resident, .type-resident {
        font-size: 18px;
        line-height: 24px;
    }

    .head-category-svg {
        width: 100px;
        height: 100px;
    }

    .wrapper-head-category {
        height: 300px;
    }

    .title-head-category {
        font-size: 14px;
    }

    .mobile-event-img {
        width: 300px;
        height: 300px;
    }

    .status-error {
        font-size: 250px;
    }

    .category-item-tag {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
    }

    .category-item-tag img {
        width: 24px;
        height: 24px;
    }

    .swiper-main-slide.one {
        background-image: url(/wp-content/themes/lpm/assets/img/slider1-760.webp);
    }

    .swiper-main-slide.two {
        background-image: url(/wp-content/themes/lpm/assets/img/slider2-760.webp);
    }

    .swiper-main-slide.three {
        background-image: url(/wp-content/themes/lpm/assets/img/slider3-760.webp);
    }

    .swiper-main-slide.four {
        background-image: url(/wp-content/themes/lpm/assets/img/slider4-760.webp);
    }

    .swiper-main-slide.five {
        background-image: url(/wp-content/themes/lpm/assets/img/kidz-cover-760.webp);
    }

    .swiper-main-slide.six {
        background-image: url(/wp-content/themes/lpm/assets/img/slider6-760.webp);
    }
}

@media (max-width: 575.98px) {

    .main-text {
        font-size: 12px;
    }

    .resident-svg {
        width: 75px;
        height: 75px;
    }

    .archive-events svg {
        width: 25px;
        height: 25px;
    }

    .header-logo-sk {
        width: 80px;
        min-width: 80px;
    }

    .header-logo {
        width: 226px;
        min-width: 226px;
    }

    .resident {
        padding: 15px;
        row-gap: 10px;
    }

    #yandex {
        height: 245px;
    }

    .title-residents {
        font-size: 10px;
    }

    .contacts-text {
        font-size: 12px;
    }

    .wrapper-list-residents,
    .time-work-content {
        margin-bottom: 60px;
    }

    .wrapper-technopark {
        margin: 0 auto 35px;
    }

    .wrapper-list-residents {
        row-gap: 10px;
    }

    .large-headline {
        font-size: 30px;
    }

    .mobile-event {
        height: 200px;
    }

    .wrapper-title-mobile-event {
        row-gap: 10px;
    }

    .text-event,
    .date-publish {
        font-size: 12px;
    }

    .inner-block-footer {
        flex-direction: column;
        row-gap: 10px;
        justify-content: center;
    }

    .title-event {
        font-size: 20px;
        line-height: 24px;
    }

    .primary-button {
        font-size: 16px;
        padding: 15px 50px;
    }

    .description-resident, .link-to-resident, .type-resident {
        font-size: 12px;
        line-height: 12px;
    }

    .time-work-resident {
        font-size: 10px;
        line-height: 12px;
    }

    .wrapper-img-category-item {
        width: 160px;
        min-width: 160px;
        height: 160px;
        min-height: 160px;
    }

    .name-resident {
        font-size: 30px;
        line-height: 34px;
    }

    .category-item {
        padding: 5px;
        column-gap: 10px;
    }

    .category-item-tag {
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 24px;
        height: 24px;
    }

    .category-item-tag img {
        width: 24px;
        height: 24px;
    }

    .wrapper-head-category {
        height: 200px;
    }

    .head-category-svg {
        width: 80px;
        height: 80px;
    }

    .title-head-category {
        font-size: 10px;
    }

    /*.description-resident {*/
    /*    -webkit-line-clamp: 3;*/
    /*    line-clamp: 3;*/
    /*}*/
    .mobile-event {
        height: 170px;
    }

    .mobile-event-img {
        width: 170px;
        height: 170px;
    }

    .status-error {
        font-size: 200px;
    }

    #yandex {
        pointer-events: none
    }

    .swiper-main-wrapper {
        height: 230px;
    }

    .swiper-main-controls {
        bottom: 10px;
    }

    .count-slide-main {
        width: 5px;
        height: 5px;
        min-width: 5px;
        min-height: 5px;
        border: 1px solid var(--white);
    }

    .block-footer {
        margin-top: 10px;
    }

    .main-text.link {
        display: none;
    }

    .main-text.link.mobile {
        display: block;
    }

}